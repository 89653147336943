var $Learnvoy_GET = [];
location.search.replace('?', '').split('&').forEach(function (val) {
    var split = val.split("=", 2);
    $Learnvoy_GET[split[0]] = split[1];
});

jQuery(document).ready(function ($) {
    /**
     * save user credits to the local browser
     */
    // Check browser support
    // if ( typeof Storage !== 'undefined' && typeof cur_user_credits_data !== 'undefined' ) {
    //   // Store
    //   localStorage.setItem( 'simpods_cpd_current_user_credits', parseInt( cur_user_credits_data['credits'] ) );
    //   localStorage.setItem( 'simpods_cpd_current_user_credits_voucher', parseInt( cur_user_credits_data['credits_voucher'] ) );

    // } 

    $('.dropdown-toggle').on('click', function (event) {
        //Prevent # showing in navigation
        event.preventDefault();
        $(this).parent().toggleClass('expand-dropdown');
    })
    $('#cpd-module-page-btn').on('click', function (event) {
        
        $('#cpd-module-pages').slideToggle( "slow", function() {
    // Animation complete.
         }); 
    } );   
    $('#cpd-general-menu-btn').on('click', function (event) {
      $('html,body').animate({
         scrollTop: $("#cpd-general-menu-btn").offset().top
      });        
        $('#bootstrap_cpd_general_nav').slideToggle( "slow", function() {
    // Animation complete.
         });
    } );  
    
    
    function cpd_move_around(){
         // toggle cpd general to bottom         
        if( $( window ).width() <= 767 ){
         
            $("#cpd-move-around").appendTo("#cpd-move-around-anker-bottom");
        } else {
            $("#cpd-move-around").appendTo("#cpd-move-around-anker");
        }   
    }
    cpd_move_around();
    $( window ).on('resize', function() {
        cpd_move_around();
    } )  

    $( document ).on( 'click', '.simpods-cpd-buy-pending', function( e ) { 
       e.preventDefault();
    });
    /**
     * update purchase data to the modal
     */
    var item_price    = 0;
    var purchase_type = '';
    $( document ).on( 'click', '.simpods-cpd-buy-now', function( e ) { 
         purchase_type = $( this ).data( 'purchase-type' );
   // $('.simpods-cpd-buy-now').on('click', function( e ){
        e.preventDefault();
        if( ! $( this ).parent().hasClass( 'simpods-cpd-info-modal-toggle' ) ){ // stop the modal if the parent element is going to toggle another modal

            $('#simpods-cpd-purchase-modal').modal('show');
            $('#simpods-cpd-confirm-purchase-feedback .simpods-cpd-purchase-feedback' ).text( '' );
            $('#simpods-cpd-purchase-modal .simpods-cpd-confirm-purchase').show();
            $('#simpods-cpd-purchase-modal .simpods-cpd-confirm-purchase').show();
            $('#simpods-cpd-purchase-modal .simpods-cpd-not-enough-credits').addClass('hidden');
            $('#simpods-cpd-purchase-modal .simpods-cpd-user-credits-after').removeClass('red bold');
            $('#simpods-cpd-purchase-modal .simpods-cpd-course-id').text( $( this ).data( 'course-id' ) );
            $('#simpods-cpd-purchase-modal .simpods-cpd-module-id').text( $( this ).data( 'module-id' ) );
            if( typeof $( this ).data( 'page-id' ) != 'undefined' ){ // for purchasing asset feedback
                $('#simpods-cpd-purchase-modal .simpods-cpd-page-id').text( $( this ).data( 'page-id' ) );    
                $('#simpods-cpd-purchase-modal .simpods-cpd-purchase-product').text( $( this ).data( 'purchase-product' ) );    
            }            

            $('#simpods-cpd-purchase-modal .simpods-cpd-item-id').text( $( this ).data( 'item-id' ) );      
            $('#simpods-cpd-purchase-modal .simpods-cpd-item-type').text( $( this ).data( 'item-type' ) );          

            var price   = parseInt( $( this ).data( 'price' ) );
            item_price  = price;
            $('#simpods-cpd-purchase-modal-title .simpods-cpd-dynamic-title').text( $( this ).data( 'title' ) );
            $('#simpods-cpd-purchase-modal .simpods-cpd-user-current-credits').text( cur_user_credits_data['credits'] );
            $('#simpods-cpd-purchase-modal .simpods-cpd-user-current-voucher-credits').text( cur_user_credits_data['credits_voucher'] );
            $('#simpods-cpd-purchase-modal .simpods-cpd-item-price').text( $( this ).data( 'price' ) );

            if( purchase_type == 'real_only' ){
              $('#purchase-type-info').show();      
            } else {
              $('#purchase-type-info').hide();        
            }

            update_sum( price, purchase_type );

            $( '#purchase_more_pages_select' ).val( 0 );
            var next_availables = $('#cpd-module-pages .fa-shopping-cart').length - parseInt( $( this ).find( $('.fa-shopping-cart') ).data('cart-index') ) ;
            //console.log( next_availables, $('#cpd-module-pages .fa-shopping-cart').length , parseInt( $( this ).find( $('.fa-shopping-cart') ).data('cart-index') ) );
            if( next_availables - 1 > 0 ){
              // only display the box if there are more pages to purchase
              var html = '<option value="0">0 ' + simpods_cpd_language.pages +'</option>';
              for( var i = 0; i < next_availables - 1 ; i ++ ){
               // console.log( $('#cpd-module-pages .fa-shopping-cart').eq( i ).data('cart-index') );
                var val  = ( i + 1 ) ;
                var page = simpods_cpd_language.pages;
                if( val == 1 ){
                  page = simpods_cpd_language.page;
                }
                html += '<option value="' + val + '">' +val + ' ' + page +'</option>';
              }
              $( '#purchase_more_pages_select' ).html( html );
              $( '#purchase_more_pages' ).removeClass( 'hidden' );
            } else {
              $( '#purchase_more_pages' ).addClass( 'hidden' );
              $( '#purchase_more_pages_select' ).val( 0 );
            }
            $( '#cpd-module-pages .fa-shopping-cart' ).each( function( x, obj ) {
              
            } );
        }
    });
    /**
     * if users want to purchase more pages
     */
    $( document ).on( 'change', '#purchase_more_pages_select', function( e ) { 
        var price = item_price;
        
        price     = ( parseInt( $( this ).val() ) + 1 ) * price;
        
        update_sum( price, purchase_type );
        
    } )
    /**
     * if update the sum on the purchase model
     */
    function update_sum( price, purchase_type ){
       
        var credits_voucher_after   = parseInt( cur_user_credits_data['credits_voucher'] );
        var credits_after           = parseInt( cur_user_credits_data['credits'] );           

        var credits_voucher_left    = 0;
        if( purchase_type == 'real_and_voucher' ){// accept real and voucher credits
          // work on voucher first
          credits_voucher_left = credits_voucher_after - price;
        } else {
          credits_voucher_left = credits_voucher_after;
          credits_after        = credits_after - price;
        }
       
        if( credits_voucher_left < 0 ){
            credits_voucher_after   = 0;
            credits_after           = credits_after + credits_voucher_left;
        } else {
            credits_voucher_after = credits_voucher_left;
        }            
        
        $('#simpods-cpd-purchase-modal .simpods-cpd-user-credits-after').text( credits_after );      
        $('#simpods-cpd-purchase-modal .simpods-cpd-user-voucher-credits-after').text( credits_voucher_after );               

        if( credits_after < 0 ){
            $('#simpods-cpd-purchase-modal .simpods-cpd-confirm-purchase').hide();
            $('#simpods-cpd-purchase-modal .simpods-cpd-not-enough-credits').removeClass('hidden');
            $('#simpods-cpd-purchase-modal .simpods-cpd-user-credits-after').addClass('red bold');
        }   

        $('#simpods-cpd-purchase-modal .simpods-cpd-item-price').text( price );   
    }

    
    function instant_purchase( args ){
        var data                = {};   
        data['action']          = 'simpods_cpd_process_purchase';        
        data['course_id']       = args['course_id'];
        data['module_id']       = args['module_id'];
        data['page_id']         = args['page_id'];
        data['product']         = args['product'];
        data['item_id']         = args['item_id'];
        data['item_type']       = args['item_type'];
        data['more_pages']      = args['more_pages'];
        data['security']        = cpd_ajax_script.nonce;
        data['is_instant']      = 1;    

        if( isNaN( data['item_id'] ) ){
            return false;
        }
        $( '#simpods-cpd-purchase-feedback-' + args['item_id'] + ' .simpods-icon-coin').addClass('rotate');
        $.post(
            cpd_ajax_script.ajaxurl,
            data,
            function( return_data ){
                $( '#simpods-cpd-purchase-feedback-' + args['item_id'] + ' .simpods-icon-coin').removeClass('rotate');
                if( return_data[ 'data' ][ 'post_type' ] === 'asset' && data['product'] === 'feedback' ){
                    if( typeof return_data[ 'data' ]['custom_feedback'] == 'undefined' ){
                        return_data[ 'data' ]['custom_feedback'] = '';
                    }
                    var info = return_data.data.reason + ' Now you have ' + return_data.data.credits_after + ' credit(s) and ' + return_data.data.credits_voucher_after + ' voucher credit(s) left in your account.'
                    return_data.data.feedback = '<span class="fas fa-info " data-toggle="tooltip" title="' + info + '"></span>' + return_data.data.feedback;
                    if( return_data.data.custom_feedback != '' ){
                        $('#asset-' + data['item_id'] + '-fb .answer-fb .custom-feedback').html( return_data.data.custom_feedback );
                    }
                    $('#asset-' + data['item_id'] + '-fb .answer-fb .preset-feedback').html( return_data.data.feedback );
                   // simpods_cpd_score_feedback( return_data[ 'data' ]['score_sets'] );
                    $('[data-toggle="tooltip"]').tooltip();
                }                  
            }
       );
    }
    $( document ).on( 'click', '.simpods-cpd-buy-instant', function( e ) { 
        //purchase_type = $( this ).data( 'purchase-type' );
   
        e.preventDefault();
        var data                = {};           
        data['course_id']       = $( this ).data( 'course-id' );
        data['module_id']       = $( this ).data( 'module-id' );
        data['page_id']         = $( this ).data( 'page-id' );
        data['product']         = $( this ).data( 'purchase-product' );
        data['item_id']         = $( this ).data( 'item-id' );
        data['item_type']       = $( this ).data( 'item-type' );
        data['more_pages']      = 0;      
        data['is_instant']      = 0;    
        instant_purchase( data );
    } );   

    /**
     * process purchase
     */
     $( document ).on( 'click', '#simpods-cpd-purchase-modal .simpods-cpd-confirm-purchase', function( e ) {
           var data_obj                = {};   
        data_obj['action']          = 'simpods_cpd_process_purchase';
        
        data_obj['course_id']       = $('#simpods-cpd-purchase-modal .simpods-cpd-course-id').text();
        data_obj['module_id']       = $('#simpods-cpd-purchase-modal .simpods-cpd-module-id').text();
        data_obj['page_id']         = 0;
        if( $('#simpods-cpd-purchase-modal .simpods-cpd-page-id').length != 0 ){
            data_obj['page_id']     = $('#simpods-cpd-purchase-modal .simpods-cpd-page-id').text();
        }
        data_obj['product']         = '';
        if( $('#simpods-cpd-purchase-modal .simpods-cpd-purchase-product').length != 0 ){
            data_obj['product']     = $('#simpods-cpd-purchase-modal .simpods-cpd-purchase-product').text();
        }
        data_obj['item_id']         = $('#simpods-cpd-purchase-modal .simpods-cpd-item-id').text();
        data_obj['item_type']       = $('#simpods-cpd-purchase-modal .simpods-cpd-item-type').text();
        data_obj['more_pages']      = parseInt( $('#simpods-cpd-purchase-modal #purchase_more_pages_select').val() );
        data_obj['security']        = cpd_ajax_script.nonce;
        
        if( isNaN( data_obj['item_id'] ) ){
            return false;
        }

        if( data_obj['more_pages'] > 0 ){
            // the user wants to purchase more subsequent pages, work out the current cart icon index
            var cur_cart_index  = parseInt( $( '#simpods-cpd-purchase-link-' + data_obj['item_id'] + ' .simpods-cpd-purchase' ).data('cart-index') ); 
            var j               = 0;

            $( '#cpd-module-pages .fa-shopping-cart' ).each( function( x, obj ) {
             
               var cart_index   = parseInt( $( this ).data('cart-index') );
               if( x > cur_cart_index && x && j < data_obj['more_pages'] ){
                  //work out the ids of the subsequent pages required
                  data_obj['item_id'] += ',' + $( this ).data('item-id');
                  
                  j ++ ;
               } 

            } );   
           
        }  

        $('#simpods-cpd-confirm-purchase-feedback .loading' ).css('display','block');

        $.post(
            cpd_ajax_script.ajaxurl,
            data_obj,
            function( return_obj ){
                $('#simpods-cpd-purchase-modal .simpods-cpd-confirm-purchase').hide();
                $('#simpods-cpd-confirm-purchase-feedback .loading' ).css('display','none');
                $('#simpods-cpd-confirm-purchase-feedback .simpods-cpd-purchase-feedback' ).text( return_obj[ 'data' ][ 'reason' ] );
                if( return_obj[ 'success' ] == true ){
                    
                    $('.simpods-cpd-purchase-link-' + data_obj['item_id'] ).removeClass('simpods-cpd-buy-now simpods-cpd-purchase');
                    $('.simpods-cpd-purchase-link-' + data_obj['item_id'] ).removeAttr( 'data-toggle' );
                    
                    if( return_obj[ 'data' ][ 'post_type' ] == 'module' || return_obj[ 'data' ][ 'post_type' ] == 'course' ){                        
                        // update the link
                        $('.simpods-cpd-purchase-link-' + data_obj['item_id'] ).addClass('module-btn start');
                        $('.simpods-cpd-purchase-link-' + data_obj['item_id'] ).html( '<span class="fas fa-unlock"></span> ' + simpods_cpd_language['Start_Now'] );                    
                        $('.simpods-cpd-purchase-link-' + data_obj['item_id'] ).html( '<span class="fas fa-unlock"></span> ' + simpods_cpd_language['Start_Now'] );
                    }
                    if( return_obj[ 'data' ][ 'post_type' ] == 'module-page' ){                        
                        // update the link
                      var page_ids  = data_obj['item_id'].split(',')  
                      var length    = page_ids.length;
                      for( var i = 0; i < length; i ++ ){
                        $('#simpods-cpd-purchase-link-' + page_ids[ i ] + ' span.simpods-cpd-purchase').remove();
                        $('#simpods-cpd-purchase-link-' + page_ids[ i ] + ' span.no-access').removeClass('no-access dark-grey');
                        $('#simpods-cpd-purchase-link-' + page_ids[ i ] ).removeAttr( 'disabled' );
                        $('#simpods-cpd-purchase-link-' + page_ids[ i ] ).removeClass('disabled');
                        $('#simpods-cpd-purchase-link-' + page_ids[ i ] ).removeClass('simpods-cpd-buy-now');
                        //if the module is not open access, open the next purchasable item.
                        $('.simpods-cpd-buy-pending').first().addClass( 'simpods-cpd-buy-now' );
                        $('.simpods-cpd-buy-pending').first().data( 'toggle', 'modal' );
                        $('.simpods-cpd-buy-pending').first().removeClass( 'simpods-cpd-buy-pending' );                        
                      }
                      $('#next-page-btn').removeClass('simpods-cpd-info-modal-toggle');

                      $('#next-page-btn').removeAttr( 'data-toggle' );
                      $('#next-page-btn').removeAttr( 'disabled' );


                       // $('.simpods-cpd-purchase-link-' + data_obj['item_id'] ).html( '<span class="fas fa-unlock"></span> ' + simpods_cpd_language['Start_Now'] );                    
                        //$('.simpods-cpd-purchase-link-' + data_obj['item_id'] ).html( '<span class="fas fa-unlock"></span> ' + simpods_cpd_language['Start_Now'] );
                    }     
                    
                    if( return_obj[ 'data' ][ 'post_type' ] === 'asset' && data_obj['product'] === 'feedback' ){
                        if( typeof return_obj[ 'data' ]['custom_feedback'] != 'undefined' && return_obj[ 'data' ]['custom_feedback'] != '' ){
                            $('#asset-' + data_obj['item_id'] + '-fb .answer-fb .custom-feedback').html( return_obj[ 'data' ]['custom_feedback'] );
                        }
                        $('#asset-' + data_obj['item_id'] + '-fb .answer-fb .preset-feedback').html( return_obj[ 'data' ]['feedback'] );
                        simpods_cpd_score_feedback( return_obj[ 'data' ]['score_sets'] );
                    }               
                    //update local storage data.
                    cur_user_credits_data['credits']            = parseInt( return_obj[ 'data' ][ 'credits_after' ] );    
                    cur_user_credits_data['credits_voucher']    = parseInt( return_obj[ 'data' ][ 'credits_voucher_after' ] );                       
                    // Use local broswer storage if possible, so even a user process orders on different pages, the credits will be synchronised.
                    // if ( typeof ( Storage ) !== 'undefined' ) {
                    //   // store        
                    //   localStorage.setItem( 'simpods_cpd_current_user_credits', parseInt( return_obj[ 'data' ][ 'credits_after' ] ) );
                    //   localStorage.setItem( 'simpods_cpd_current_user_credits_voucher', parseInt( return_obj[ 'data' ][ 'credits_voucher_after' ] ) );     
                       
                    // }       
                    // reset the value for the cart-indexes so we can work out how many purchasable pages after this one.        
                    for( var i = 0; i < $('#cpd-module-pages .fa-shopping-cart').length ; i ++ ){
                        $('#cpd-module-pages .fa-shopping-cart').eq( i ).attr('data-cart-index', i ) ;
                    }                    
                }
            }        
       );    
    });    

    /**
     * popup text info
     */

     $( document ).on( 'click', '.simpods-cpd-info-modal-toggle', function( e ) {
        e.preventDefault();
        
        $( '#simpods-cpd-info-modal' ).modal('show');     
        $( '#simpods-cpd-info-modal-title' ).text( '' );
        $( '#simpods-cpd-info-modal-body' ).text( '' );

        $( '#simpods-cpd-info-modal-title' ).text( $( this ).data( 'title' ) );
       
        var show_content = $( this ).data( 'show-content' );
        if( 'undefined' != typeof show_content && '' != show_content ){ 
            var html = '';
            if( show_content.indexOf('module_') === 0 ){ 
                if( modules.length != 0 ){ 
                    html = modules[ show_content ]['featured_img'] + modules[ show_content ]['post_content'];
                }
            }
            if( '' != html ){ 
                $( '#simpods-cpd-info-modal-body' ).html( html );
            }
            
        } else {
            $( '#simpods-cpd-info-modal-body' ).text( $( this ).data( 'info' ) );    
            
        }
        
     } );
    /**
     * popup post_content
     */
     $( document ).on( 'click', '.simpods-cpd-post-modal-toggle', function( e ) {
        e.preventDefault();
        $( '#simpods-cpd-post-modal' ).modal('show');     
        $( '#simpods-cpd-post-modal-title' ).text( '' );
        $( '#simpods-cpd-post-modal-body' ).text( '' );

       // $( '#simpods-cpd-post-modal-title' ).text( $( this ).data( 'title' ) );
       // $( '#simpods-cpd-post-modal-body' ).text( $( this ).data( 'info' ) );
   
     } )
     $( document ).on( 'click', '.no-access, .disabled, a:disabled, button:disabled', function( e ) {
      
        e.preventDefault();
     } )

     /**
      * apply for courses
      */
      $(document).on('click', '.apply-course', function (evt) {
       
        evt.preventDefault();
        var this_btn = $('.btn-' + $(this).data('course-id') );
        var data_obj = {};
        data_obj['action'] = 'cpd_apply_course';
        data_obj['course_id'] = $(this).data('course-id');
        data_obj['user_affiliation'] = cur_user_affiliation;
        data_obj['security'] = cpd_ajax_script.nonce;        
        $('.apply-course-' + data_obj['course_id'] + '-load-img .loading').css('display', 'block');
        $.post(cpd_ajax_script.ajaxurl, data_obj ).done( function (return_obj) {
         
          $('.apply-course-' + data_obj['course_id'] + '-load-img .loading').css('display', 'none'); 

          if (return_obj['success'] == 1) { 
            
            if ( parseInt( return_obj.data['auto_approval'] ) == 1 ) {
              this_btn.text(simpods_cpd_language['Start_Now']);
              this_btn.attr('disabled', false);
            } else {
              this_btn.text( simpods_cpd_language['Awaiting_Approval'] );
              this_btn.addClass('simpods-cpd-info-modal-toggle');
              this_btn.attr('data-target','#simpods-cpd-info-modal');
              this_btn.attr('data-title', simpods_cpd_language['Why_disabled']);
              this_btn.attr('data-info', simpods_cpd_language['Awaiting_Approval'] );
              this_btn.attr('disabled', true);
            }

            this_btn.removeClass('apply-course');
          } else {
            this_btn.text(simpods_cpd_language['Failed']);
            this_btn.attr('disabled', false);
          }

          $.fn.matchHeight._update();
        });
       
      });
      $('.awaiting_approval').on('click', function (e) {
        e.preventDefault();
      });  

    /**
    * display course promotion messages
    */

    $(document).on('click', '.simpods-cpd-promotion-modal-toggle', function (e) { 
      e.preventDefault(); 
      if( typeof simpods_cpd_data != 'undefined' ){ 
        $('#simpods-cpd-promotion-modal').modal('show');
        $('#simpods-cpd-promotion-modal-title').text('');
        $('#simpods-cpd-promotion-modal-body').text('');
        $('#simpods-cpd-promotion-modal-title').text( simpods_cpd_data[ 'courses' ][ $( this ).data( 'id' ) ][ 'post_title' ] );
        // if( simpods_cpd_data[ 'courses' ][ $( this ).data( 'id' ) ][ 'promotion_text' ] == '' ){
        //   simpods_cpd_data[ 'courses' ][ $( this ).data( 'id' ) ][ 'promotion_text' ] = simpods_cpd_data[ 'courses' ][ $( this ).data( 'id' ) ][ 'post_content' ];
        // }
        var html = '';
        if( simpods_cpd_data[ 'courses' ][ $( this ).data( 'id' ) ][ 'learning_outcome' ] != null ){
          html = html + simpods_cpd_data[ 'courses' ][ $( this ).data( 'id' ) ][ 'learning_outcome' ];
        }
        if( simpods_cpd_data[ 'courses' ][ $( this ).data( 'id' ) ][ 'requirements' ] != '' ){
          html = html +  simpods_cpd_data[ 'courses' ][ $( this ).data( 'id' ) ][ 'requirements' ];
        }
        if( simpods_cpd_data[ 'courses' ][ $( this ).data( 'id' ) ][ 'promotion_text' ] != '' ){
          html = html +  simpods_cpd_data[ 'courses' ][ $( this ).data( 'id' ) ][ 'promotion_text' ];
        }
        $('#simpods-cpd-promotion-modal-body').html( html );

      }
    }); 
    /**
    * display course promotion messages
    */

    $(document).on('click', '.simpods-cpd-post-modal-toggle', function (e) { 
      e.preventDefault(); 
      if( typeof simpods_cpd_data != 'undefined' ){ 
        $('#simpods-cpd-post-modal').modal('show');
        $('#simpods-cpd-post-modal-title').text('');
        $('#simpods-cpd-post-modal-body').text('');
        $('#simpods-cpd-post-modal-title').text( simpods_cpd_data[ 'posts' ][ $( this ).data( 'id' ) ][ 'post_title' ] );

        var html = '';
        if( simpods_cpd_data[ 'posts' ][ $( this ).data( 'id' ) ][ 'post_content' ] != null ){
          html = html + simpods_cpd_data[ 'posts' ][ $( this ).data( 'id' ) ][ 'post_content' ];
        }

        $('#simpods-cpd-post-modal-body').html( html );

      }
    });        
    /**
     * rating system
     */ 
    $('#simpods-comment-form p.stars a').on( 'hover', function(){

        var cur_value = parseInt( $( this ).text() ) ;
        for( var i = 1; i <= 5 ; i ++ ){
            if( i > cur_value ){
              $( '#simpods-comment-form p.stars a.star-' + i ).addClass( 'not-shaded' );
            } else {
              $( '#simpods-comment-form p.stars a.star-' + i ).removeClass( 'not-shaded' );
            }                      
        }

    } );   
    
    $('#simpods-comment-form p.stars a').on('click',  function(){
        var cur_value = parseInt( $( this ).text() ) ;
        for( var i = 1; i <= 5 ; i ++ ){
            if( i > cur_value ){
              $( '#simpods-comment-form p.stars a.star-' + i ).removeClass( 'shaded' );
            } else {
              $( '#simpods-comment-form p.stars a.star-' + i ).addClass( 'shaded' );
            }                      
        }
        $('#simpods-rating').val( cur_value );

    } );      
   /**
    * play audio by shortcode audio-button
    */
   $( document ).on( 'click', '.simpods-cpd-audio-button' , function(){
      var audio_btn = $( this );
      var id        = $( this ).data( 'id' );
      var cur_audio = document.getElementById( id );
      if( $( this ).children().children().hasClass( 'fa-volume-down' ) ){
        cur_audio.play();
        $( this ).children().children().removeClass( 'fa-volume-down' );
        $( this ).children().children().addClass( 'fa-volume-up' );        
      } else {
        cur_audio.pause();
        cur_audio.currentTime = 0;
        $( this ).children().children().removeClass( 'fa-volume-up' );
        $( this ).children().children().addClass( 'fa-volume-down' );        
      }
      cur_audio.onpause=function(){
        audio_btn.children().children().removeClass( 'fa-volume-up' );
        audio_btn.children().children().addClass( 'fa-volume-down' );        
      }
    });

   $( document ).on( 'click', '.btn' , function( e ){     
   
      if( typeof $( this ).data('target') === 'undefined' && typeof $( this ).parent().data('target') === 'undefined' ){
        
        if( $( this ).parent().find('.loading-gif').length != 0 && '_blank' != $( this ).prop('target') ){
          $( this ).parent().find('.loading-gif').find('.loading').css('display', 'block');
        }    
      }
   });

  $( document ).on( 'click', '.score-feedback-to-asset-btn' , function( e ){     
    e.preventDefault();
    var to_asset_id = $( this ).data( 'scroll-to' );
     $('html,body').animate({
       scrollTop: $( '#asset-box-' + to_asset_id ).offset().top - 100
    }, 1000, function() {
      $( '#asset-box-' + to_asset_id ).fadeOut(300).fadeIn(300);
    });
  }) 

  if( typeof simpods_cpd_scroll_to_asset != 'undefined' && $.isArray( simpods_cpd_scroll_to_asset )  && ! isNaN( simpods_cpd_scroll_to_asset[0] ) ){ // $.isNumeric( simpods_cpd_scroll_to_asset[0]
    $('html,body').animate({
       scrollTop: $( '#asset-box-' + simpods_cpd_scroll_to_asset[0] ).offset().top - 100
    }, 1000, function() {
      $( '#asset-box-' + simpods_cpd_scroll_to_asset[0] ).fadeOut(300).fadeIn(300);
    });
    
  }

  if( typeof $.fancybox === 'object' ){   
    $('a.fancybox').fancybox({
      // Options will go here
    });    

  }
    /**
     * Trigger Bootstrap tooltip
     */ 
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })  
    /**
     * Add "current_item active" to the related li tag in the meun 
     */ 
    if( $('.navbar-nav').length != 0 ) {
        
        if( typeof $Learnvoy_GET['list-documents'] != 'undefined' ){
            $( 'a[href*="list-documents=1"]' ).each( function( x, element ) {
                $( this ).parent('li').addClass( 'current_item active' );         
            });
        }
        if( typeof $Learnvoy_GET['online-courses'] != 'undefined' ){
            $( 'a[href*="online-courses=1"]' ).each( function( x, element ) {
                $( this ).parent('li').addClass( 'current_item active' );         
            });           
        }
        if( typeof $Learnvoy_GET['offline-courses'] != 'undefined' ){
            $( 'a[href*="offline-courses=1"]' ).each( function( x, element ) {
                $( this ).parent('li').addClass( 'current_item active' );         
            });           
        }     
        if( typeof $Learnvoy_GET['blended-courses'] != 'undefined' ){
            $( 'a[href*="blended-courses=1"]' ).each( function( x, element ) {
                $( this ).parent('li').addClass( 'current_item active' );         
            });           
        }    
        if( typeof $Learnvoy_GET['all-courses'] != 'undefined' ){
            $( 'a[href*="all-courses=1"]' ).each( function( x, element ) {
                $( this ).parent('li').addClass( 'current_item active' );         
            });           
        }                    
    }
    /**
     * Courses filter and search
     */ 
    $('#course-keywords-search').on('click', function(){
        $('#courses-filter-form').submit();
    })
    $('#filter-course').on('change', function(){
        $('#courses-filter-form').submit(); 
        
    })

    // $('.simpods-cpd-document-download-btn').on('click', function( evt ) {
    //     evt.preventDefault();
    //     window.location.href = $( this ).attr('href');
    // })
    //$.fn.matchHeight._update()    
});
/**
 * get script
 * @param  string   source   url
 * @param  Function callback function to call back
 * @link   https://stackoverflow.com/questions/16839698/jquery-getscript-alternative-in-native-javascript
 * @return null
 */
function get_script( source, callback ) {
    var script 		= document.createElement('script');
    var prior 		= document.getElementsByTagName('script')[0];
    script.async 	= 1;

    script.onload = script.onreadystatechange = function( _, isAbort ) {
        if(isAbort || !script.readyState || /loaded|complete/.test(script.readyState) ) {
            script.onload = script.onreadystatechange = null;
            script = undefined;

            if(!isAbort) { if(callback) callback(); }
        }
    };

    script.src = source;
    prior.parentNode.insertBefore(script, prior);
    
}

function simpods_cpd_esc_string( a_string, remove_parenthesis = true ){
    if( isNaN( a_string ) ){
        if( remove_parenthesis == true ){
            a_string = a_string.replaceAll(/[{()}[\]]/g, '');
        }
        return a_string.replaceAll(/(<([^>]+)>)/gi, '');
    }
    return a_string;
}

// // PREVENT CONTEXT MENU FROM OPENING
// document.addEventListener("contextmenu", function(evt){
//   evt.preventDefault();
// }, false);

// // PREVENT CLIPBOARD COPYING
// document.addEventListener("copy", function(evt){
//   // Change the copied text if you want
//   evt.clipboardData.setData("text/plain", "Copying is not allowed on this webpage");
 
//   // Prevent the default copy action
//   evt.preventDefault();
// }, false);
var preset_colour   = '#36a2eb'; 
/**
 * random colours
 * @link https://gist.github.com/mikebmou/1323655
 */
var preset_colours = ["#36a2eb","#dc3912","#ff9900","#109618","#990099","#0099c6","#dd4477","#66aa00","#b82e2e","#316395","#3366cc","#994499","#22aa99","#aaaa11","#6633cc","#e67300","#8b0707","#651067","#329262","#5574a6","#3b3eac","#b77322","#16d620","#b91383","#f4359e","#9c5935","#a9c413","#2a778d","#668d1c","#bea413","#0c5922","#743411"];

function generate_random_colour() {
  var characters = '0123456789ABCDEF';
  var colour = '#';
  for (var i = 0; i < 6; i++) {
    colour += characters[ Math.round( Math.random() * 16 ) ];
  }
  return colour;
}
function generate_random_colours( how_many ) {
  var colours = [];
  if( Number.isInteger( how_many ) ){
   
    
    for (var i = 0; i < how_many; i++) {
      var color = generate_random_colour();
      colours.push( color ); 
    }
  }
  return colours;
}

/**
 * Validating url
 * @link https://stackoverflow.com/questions/2723140/validating-url-with-jquery-without-the-validate-plugin
 */
function simpods_cpd_is_url_valid( url ) {
    return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url);
}
/**
 * scroll to a div
 */ 

function simpods_cpd_scroll_to( id ){    
    // target element
    var element = jQuery( '#' + id );
    if ( element.length === 0) {
        return;
    }
    // not visible=false and display:none 
    if( element.is(':visible') && ! element.is(':hidden') ){
        jQuery('html, body').animate({
            scrollTop: element.offset().top - 30
        }, 1000, function(){
            element.fadeTo(200, 0.1).fadeTo(200, 1.0);
        });    
    }
}


